import { Config } from "./config";

export const config: Config = {
    wsUrl: 'wss://api.parla.tech',
    apiUrl: 'https://api.parla.tech/api/v1',
    apiUrlThirdParty: 'https://api.parla.tech/third-party',
    assetUrl: 'https://assets.parla.tech',
    admin: 'admin.parla.tech',
    environment: undefined,
    webapp: '.parla.tech',
    pixabayID: '20335603-4ebef7392b7ae8473ded4d96c',
    unsplashID: '1aPjRkXGjR1-pNxVF5YADmcyW5L17vfDnwcYT2JMCko',
    pollingEnabled: true,
    domain: 'parla.tech',
    OPENAI_API_KEY: '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'
}